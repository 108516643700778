class Util {
	static elIndex(el) {
		return [...el.parentElement.children].indexOf(el);
	}

	static ucFirst(s) {
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

	static hasProperty(object, key) {
		return Object.prototype.hasOwnProperty.call(object, key);
    }

    /**
     * @param string
     * @returns Element[]
     */
    static parseStringToElements(string) {
        const nodes = [];
        const templateElement = document.createElement('template');
        templateElement.innerHTML = string;
        for (let i of templateElement.content.children) {
            if (i.tagName.toLowerCase() === 'script') {
                const newTag = document.createElement("script");
                newTag.text = i.text;
                for (let attr of i.attributes) {
                    newTag.setAttribute(attr.nodeName, attr.nodeValue);
                }
                nodes.push(newTag)
            } else {
                nodes.push(i)
            }
        }

        return nodes;
    }

    static appendToBody(string) {
        Util.parseStringToElements(string).forEach(el => document.body.appendChild(el))
    }

}
